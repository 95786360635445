import {
  tss, useThemeContext,
} from 'theme';
import { FontSize, fontSizeMixin, fontSizeMixinForIframe } from 'theme/fonts';

export const getShift4ComponentsStyles = (theme: ReturnType<typeof useThemeContext>['theme']) => ({
  style: {
    base: {
      color: theme.colors.text.primary,
      ...fontSizeMixinForIframe(FontSize.M),
      fontFamily: theme.fonts.primary,
      '::placeholder': {
        color: theme.colors.text.inactive,
      },
    },
    invalid: {
      color: theme.colors.text.error,
      '::placeholder': {
        color: theme.colors.text.inactive,
      },
    },
  },
  fonts: [{
    family: theme.fonts.primary,
    style: 'normal',
    weight: 400,
    display: 'swap',
    src: "url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');",
  }],
});

export const getShift4GlobalStyles = (theme: ReturnType<typeof useThemeContext>['theme']) => ({
  '.Shift4Component--empty': {
    '& svg': {
      fill: theme.colors.icons.inactive,
    },
  },
  '.Shift4Component--focus': {
    outline: `1px solid ${theme.colors.border.accentDark}`,
  },
  '.Shift4Component--invalid': {
    outline: `1px solid ${theme.colors.border.error}`,
    '& svg': {
      fill: theme.colors.icons.error,
    },
  },
});

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      position: 'relative',
      alignItems: 'flex-start',
    },
    inline: {
      display: 'flex',
      gap: 15,
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 15,
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
      marginBottom: theme.scale(15),
    },
    stepTextWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
    cardNotice: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      ...fontSizeMixin(FontSize.XS),
      marginTop: -9,
      color: theme.colors.text.inactive,
    },
    proceedButtonWrapper: {
      marginTop: 'auto',
    },
    label: {
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
    },
    shift4InputWrapper: {
      display: 'table',
    },
    shift4InputField: {
      background: theme.colors.background.primary,
      padding: '4px 12px',
      borderRadius: theme.border.radius,
      position: 'relative',
      height: theme.scale(32),
      display: 'table-cell',
      verticalAlign: 'middle',
      '& svg': {
        width: theme.scale(16),
        height: theme.scale(16),
        fill: theme.colors.icons.inactive,
        flexShrink: 0,
      },
    },
    formItemsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      width: '100%',
    },
    formItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    formItemInvalid: {
      outline: `1px solid ${theme.colors.border.error}`,
      '& svg': {
        fill: theme.colors.icons.error,
      },
    },
    expiryDateField: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.scale(8),
      width: theme.scale(105),
      '& > div': {
        width: '100%',
      },
    },
    cvcField: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.scale(8),
      width: theme.scale(80),
      '& > div': {
        width: '100%',
      },
    },
    loadingOverlay: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.colors.background.containerFill,
      top: 0,
      left: 0,
      zIndex: 1,
      borderRadius: theme.border.radius,
    },
    genericFormError: {
      textAlign: 'center',
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
      marginBottom: 6,
    },
    existingCardContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      color: theme.colors.text.primary,
      ...fontSizeMixin(FontSize.S),
      whiteSpace: 'nowrap',
      fontWeight: 500,
      width: '100%',
    },
    existingCardField: {
      display: 'flex',
      alignItems: 'center',
      background: theme.colors.background.primary,
      padding: '8px 12px',
      borderRadius: theme.border.radius,
      width: '100%',
    },
    cardBrandImage: {
      height: theme.scale(20),
      width: 35,
      marginRight: 8,
    },
    editButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      marginLeft: 'auto',
      '& > div': {
        padding: 'unset',
      },
    },
    cancelButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: `1px solid ${theme.colors.separator.primary}`,
      paddingTop: 12,
    },
    cancelButton: {
      background: 'none',
      cursor: 'pointer',
      color: theme.colors.text.accentDark,
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
      border: 'none',
      '& > div': {
        padding: 'unset',
      },
    },
    rememberCardCheckbox: {
      '& div': {
        color: theme.colors.text.inactive,
        fontSize: theme.scale(14),
      },
    },
    rememberCardCheckboxActive: {
      '& div': {
        color: theme.colors.text.primary,
      },
    },
    addCardButton: {
      marginTop: 'auto',
      marginRight: theme.scale(10),
      marginBottom: theme.scale(10),
    },
    plusIcon: {
      transform: 'rotate(45deg)',
    },
    setDefaultCardButton: {
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      color: theme.colors.text.inactive,
      '& > div': {
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        padding: 0,

      },
    },
    setDefaultCardButtonActive: {
      color: theme.colors.text.primary,
    },
    actions: {
      minHeight: 24,
      display: 'flex',
      gap: theme.scale(15),
    },
    errorMessage: {
      color: theme.colors.text.error,
      fontWeight: 500,
      fontSize: theme.scale(12),
      textAlign: 'center',
      padding: `0  ${theme.scale(10)}px ${theme.scale(10)}px ${theme.scale(15)}px`,
    },
    listOfCardsErrorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: theme.scale(10),
      paddingRight: theme.scale(10),
      fontWeight: 500,
      color: theme.colors.text.inactive,
      textAlign: 'center',
      ...fontSizeMixin(FontSize.M),
    },
    errorIcon: {
      fill: theme.colors.icons.error,
    },
    cardErrorMessage: {
      color: theme.colors.text.error,
      fontWeight: 500,
      textAlign: 'center',
      padding: `${theme.scale(6)}px 0`,
      ...fontSizeMixin(FontSize.S),
    },
  }),
);

export default useStyles;
