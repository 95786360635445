import {
  SyntheticEvent, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import Card from 'components/card';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import {
  SearchableList,
  SearchableListItemValues,
  SearchableListProps,
} from 'components/searchableList';
import SlideModal from 'components/slideModal';
import Spinner from 'components/spinner';
import { IconArrow } from 'icons/arrow';
import GlobeIllustration from 'illustrations/globe';
import { CountryItem } from 'services/countries';
import { User } from 'types/user';
import { locators, QALocator } from 'utils/tests/QA';

import CountryCheckInfoBlock from './countryCheckInfoBlock';
import KEYS, { LABELS } from './keys';
import useStyles from './styles';

interface CountryCheckProps {
  onCountryUpdate: (country: CountryItem | null) => Promise<void>;
  isUpdatingUserCountry: boolean;
  isViewLoading: boolean;
  countries?: CountryItem[];
  user?: User;
  preselectedCountry: CountryItem | null;
  goBack: () => void;
}

const CountryCheck = ({
  goBack,
  countries = [],
  onCountryUpdate,
  isViewLoading,
  isUpdatingUserCountry,
  preselectedCountry,
}: CountryCheckProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isCountryListVisible, setCountryListVisible] = useState<boolean>(false);
  const [country, setCountry] = useState<CountryItem | null>(preselectedCountry);

  useEffect(() => {
    if (!country && preselectedCountry) {
      setCountry(preselectedCountry);
    }
  }, [preselectedCountry]);

  const searchableCountryList = useMemo(() => {
    const mappedList = countries.map((record: CountryItem) => ({
      id: record.isoAlpha2,
      icon: (<img src={record.iconUrl} alt={record.name} className={classes.countryIconDropdown} />),
      // TODO: User i18n country names
      label: record.name,
    }));

    return mappedList as SearchableListItemValues[];
  }, [countries]);

  const submitCountryId = () => {
    onCountryUpdate(country);
  };

  const onSelectCoutry: SearchableListProps['onChange'] = (
    _event: SyntheticEvent,
    value,
  ) => {
    const selectedCountry = countries
      .find(record => record.isoAlpha2 === (value as SearchableListItemValues)?.id);
    setCountry(selectedCountry || null);
    closeCountriesList();
  };

  const closeCountriesList = () => {
    setCountryListVisible(false);
  };

  const openCountriesList = () => {
    setCountryListVisible(true);
  };

  return (
    <div className={classes.container}>
      <Header
        hasBackButton
        onBackClick={goBack}
      >
        {t(LABELS.HEADER)}
      </Header>
      <div className={classes.imageWrapper}>
        <GlobeIllustration />
        {t(LABELS.HEADER_CAPTION)}
      </div>
      <Card className={classes.card}>
        <OptionallyVisible visible={isViewLoading}>
          <Spinner
            {...QALocator(locators.page.countryCheck.viewLoadingSpinner)}
          />
        </OptionallyVisible>
        <OptionallyVisible visible={!isViewLoading}>
          <div className={classes.formItem}>
            <label className={classes.label} htmlFor={KEYS.COUNTY_INPUT_ID}>
              {t(LABELS.COUNTRY_INPUT_TITLE)}
            </label>
            <Button
              className={classes.countrySelectButton}
              onClick={openCountriesList}
              {...QALocator(locators.page.countryCheck.selectCountryButton)}
            >
              {country?.name ?? t(LABELS.CHOOSE_COUNTRY)}
              <div className={classes.countryIconWrapper}>
                <OptionallyVisible visible={Boolean(country)}>
                  <img src={country?.iconUrl} className={classes.countryIcon} alt={country?.name} />
                </OptionallyVisible>
                <IconArrow width={8} height={8} className={classes.currencyButtonArrow} />
              </div>
            </Button>
            <CountryCheckInfoBlock />
          </div>
        </OptionallyVisible>
      </Card>
      <SlideModal
        visible={Boolean(isCountryListVisible)}
        onClose={closeCountriesList}
        title={t(LABELS.CHOOSE_COUNTRY)}
      >
        <SearchableList
          options={searchableCountryList}
          onChange={onSelectCoutry}
          searchPlaceholderText={t(LABELS.COUNTRIES_SEARCH)}
          searchResultsLabel={t(LABELS.COUNTRIES_ALPHABETICAL)}
        />
      </SlideModal>
      <div
        className={classes.proceedButtonWrapper}
      >
        <Button
          isContinue
          onClick={submitCountryId}
          isLoading={isUpdatingUserCountry}
          disabled={!country}
          {...QALocator(locators.page.countryCheck.proceedButton)}
        >
          {t(LABELS.CONTINUE)}
        </Button>
      </div>
    </div>
  );
};

export default CountryCheck;
