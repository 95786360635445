import { Trans, useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/base';

import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import CARD_BRANDS_IMAGE_SOURCE from 'constants/cardBrands';
import IconError from 'icons/error';
import CardIllustration from 'illustrations/card';
import { SavedCard } from 'services/payments/types';
import { CardPaymentToken } from 'types/card';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { CardBrand } from 'types/transactions';
import { User } from 'types/user';

import { Form } from './form';
import { LABELS } from './keys';
import useStyles from './styles';

interface BankDetailsScreenProps {
  paymentsConfig: {
    shift4PublicKey?: string;
    merchantId?: string;
    isLoading: boolean;
    isError: boolean;
  };
  user?: User;
  tokenizedCard: CardPaymentToken | SavedCard | null;
  canGoBack: boolean;
  ctaButtonProps?: Partial<ButtonProps>;
  showSaveCardControl?: boolean;
  showSetDefaultControl: boolean;
  orderError?: string;
  errorMessage?: string;
  showNotice?: boolean;
  isAddingNewCard: boolean;
  cards: {
    cards: SavedCard[];
    isLoading: boolean;
    error: any;
  };
  proceed: () => void;
  goBack: () => void;
  showErrorScreen: (errorType: ScreenErrorType, isFatalError?: boolean) => void;
  setTokenizedCard: (tokenizedCard: CardPaymentToken | SavedCard, rememberCard: boolean, setAsDefault: boolean) => void;

}

const BankDetailsScreen = ({
  paymentsConfig,
  tokenizedCard,
  canGoBack,
  proceed,
  goBack,
  showErrorScreen,
  setTokenizedCard,
  user,
  ctaButtonProps,
  showSaveCardControl = false,
  showSetDefaultControl = false,
  orderError,
  errorMessage,
  showNotice = true,
  isAddingNewCard,
  cards,
}: BankDetailsScreenProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Header
        hasBackButton={canGoBack}
        onBackClick={goBack}
      >
        {t(LABELS.HEADER)}
      </Header>
      <div className={classes.imageWrapper}>
        <CardIllustration />
        <div className={classes.stepTextWrapper}>
          <Trans
            i18nKey={LABELS.STEP_TEXT}
            components={{
              img1: <img alt={CardBrand.Visa} src={CARD_BRANDS_IMAGE_SOURCE[CardBrand.Visa]} />,
              img2: <img alt={CardBrand.MasterCard} src={CARD_BRANDS_IMAGE_SOURCE[CardBrand.MasterCard]} />,
            }}
          />
        </div>
        <OptionallyVisible visible={showNotice}>
          <div className={classes.cardNotice}>
            <IconError color={theme.colors.icons.inactive} width={14} height={14} />
            {t(LABELS.CARD_NOTICE)}
          </div>
        </OptionallyVisible>
      </div>
      <OptionallyVisible visible={Boolean(errorMessage)}>
        <div className={classes.errorMessage}>{errorMessage}</div>
      </OptionallyVisible>
      <Form
        shift4PublicKey={paymentsConfig.shift4PublicKey}
        merchantId={paymentsConfig.merchantId}
        proceed={proceed}
        showErrorScreen={showErrorScreen}
        tokenizedCard={tokenizedCard}
        setTokenizedCard={setTokenizedCard}
        user={user}
        ctaButtonProps={ctaButtonProps}
        showSaveControl={showSaveCardControl}
        showSetDefaultControl={showSetDefaultControl}
        orderError={orderError}
        isAddingNewCard={isAddingNewCard}
        cards={cards}
      />
    </div>
  );
};

export default BankDetailsScreen;
