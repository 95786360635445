import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss.create(
  ({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
      height: '100%',
    },
    header: {
      padding: '3px 10px',
      height: theme.scale(47),
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      maxWidth: theme.scale(87),
      userSelect: 'none',
    },
    label: {
      ...fontSizeMixin(FontSize.S),
      fontWeight: 500,
    },
    formItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(5),
    },
    countrySelectButton: {
      border: 'none',
      cursor: 'pointer',
      background: theme.colors.background.primary,
      borderRadius: theme.border.radius,
      padding: theme.scale(10),
      '&:hover': {
        background: theme.colors.background.highlight,
      },
      '& > div': {
        fontWeight: 600,
        ...fontSizeMixin(FontSize.M),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.scale(6),
        padding: 0,
      },
    },
    countryIconWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.scale(6),
    },
    countryIcon: {
      width: theme.scale(16),
      height: theme.scale(16),
      borderRadius: 8,
    },
    countryIconDropdown: {
      width: theme.scale(20),
      height: theme.scale(20),
      borderRadius: 10,
    },
    currencyButtonArrow: {
      fill: theme.colors.text.inactive,
      transform: 'rotate(90deg)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.scale(12),
      alignItems: 'center',
    },
    proceedButtonWrapper: {
      marginTop: 'auto',
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    formItemError: {
      ...fontSizeMixin(FontSize.S),
      color: theme.colors.text.error,
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.scale(15),
      ...fontSizeMixin(FontSize.M),
      color: theme.colors.text.inactive,
    },
  }),
);

export default useStyles;
