import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import OptionallyVisible from 'components/optionallyVisible';
import { createGooglePaymentDataRequest } from 'components/payment/googlePay/utils';
import quoteDetails from 'components/quoteDetails';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import { canGoBackFromPaymentMethods } from 'navigation/paymentMethod';
import useShift4Components from 'pages/bankDetails/useShift4Components';
import paymentsApi, { FINISH_PAYMENT_CACHE_KEY, selectPaymentMethod } from 'services/payments';
import {
  CreatePaymentMethodPayload, OrderCompletionPayload, OrderCompletionResponse, SavedCard,
} from 'services/payments/types';
import quotesApi, { QUOTE_CACHE_KEY, QuoteAction } from 'services/quote';
import { QuotePayload } from 'services/quote/transactions/payloads';
import transactionApi from 'services/transaction';
import { selectUserCacheEntry } from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import {
  selectCryptoAsset, selectFiatAsset, selectOrder, selectTransactionId, setTransactionId,
} from 'state/slices/orderSlice';
import {
  selectHasCardAuthorizationError,
  selectIsDefaultCard,
  selectRememberCard,
  selectTokenizedCard,
  setCardAuthorizationErrorMessage,
  setHasCardAuthorizationError,
  setPaymentMethod,
  setTokenizedCard,
} from 'state/slices/paymentSlice';
import { changeStep, setIsAddingCard } from 'state/slices/stepSlice';
import { RootState } from 'state/store';
import { CurrencyType, Quote } from 'types/assets';
import { CardPaymentToken } from 'types/card';
import ApiErrorCode, { APIErrorResponse } from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import Flow from 'types/flow';
import { ReduxToolkitError, ReduxToolkitResponse, Response } from 'types/http';
import PaymentMethod from 'types/payment';
import ConfigQueryParam from 'types/queryParams';
import { WorkflowStep } from 'types/step';
import { TransactionId } from 'types/transactions';
import { KycStatus } from 'types/user';
import getRedirectUrl from 'utils/domain';
import { formatFiatCurrencyI18n } from 'utils/i18n';
import isRunningInsideIframe from 'utils/iframe';
import { appConfig } from 'utils/local';
import Logger from 'utils/logger';
import { payWithApplePay, payWithApplePayInsideIframe } from 'utils/payments/applePay';
import payWithCard from 'utils/payments/card';
import getQueryParam from 'utils/queryParams';

import CheckoutPage from '.';
import CardPreview from './components/cardPreview';
import WalletPreview from './components/walletPreview';
import { KEYS, LABELS } from './keys';
import QuoteAdditionalInfo from './quoteAdditionalInfo';
import useStyles from './styles';
import { GPayPaymentMethod } from './types';

const CheckoutContainer = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [quoteMutation, {
    data: quoteData,
    isLoading: quoteIsLoading,
    isError: isQuoteError,
    error: quoteError,
  }] = quotesApi.useGetQuoteMutation({
    fixedCacheKey: QUOTE_CACHE_KEY,
  });
  const [completePaymentMutation, {
    isError: isPaymentError,
    error: paymentError,
    reset: resetPaymentError,
  }] = paymentsApi.useCompletePaymentMutation({
    fixedCacheKey: FINISH_PAYMENT_CACHE_KEY,
  });

  const selectedFiatAsset = useSelector(selectFiatAsset);
  const selectedCryptoAsset = useSelector(selectCryptoAsset);
  const tokenizedCard = useSelector(selectTokenizedCard);
  const rememberCard = useSelector(selectRememberCard);
  const isCardSetByDefault = useSelector(selectIsDefaultCard);
  const order = useSelector(selectOrder);
  const authToken = useSelector(selectAuthToken) ?? '';
  const user = useSelector(selectUserCacheEntry);
  const isCardManagementVisibleByDefault = Boolean(useSelector(selectHasCardAuthorizationError));
  const defaultPaymentMethod = useSelector(selectPaymentMethod);
  const paymentMethod = useSelector((state : RootState) => state.payment.paymentMethod);
  const selectedPaymentMethod = paymentMethod ?? defaultPaymentMethod;
  const orderTransactionId = useSelector(selectTransactionId);
  const externalOrderId = getQueryParam(ConfigQueryParam.ExternalOrderId) ?? undefined;
  const externalUserId = getQueryParam(ConfigQueryParam.ExternalUserId) ?? undefined;
  const redirectUrl = getRedirectUrl(getQueryParam(ConfigQueryParam.RedirectUrl));
  transactionApi.useGetTransactionQuery({
    transactionId: orderTransactionId!,
    includeToken: true,
  }, {
    skip: !orderTransactionId,
  });

  const { data } = paymentsApi.useGetPaymentsConfigQuery(authToken);
  const { shift4Utils } = useShift4Components(data?.data?.shift4PublicKey, data?.data?.merchantId);
  const { proceed, goBack, canGoBack } = useNavigation();
  const [createPaymentMethod] = paymentsApi.useCreatePaymentMethodMutation();

  const withdrawalAddress = order.walletAddress;
  const withdrawalAddressTag = order.tagOrMemo ? order.tagOrMemo : undefined;
  const canEditWallet = canGoBackFromPaymentMethods({ user: user?.data?.data });

  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken, {
    skip: user?.data?.data?.kycStatus !== KycStatus.Approved,
  });

  const isCardSpecified = paymentMethod === PaymentMethod.Card ? tokenizedCard : true;
  const isPayReady = Boolean(shift4Utils && isCardSpecified);

  const setCard = (
    tokenizedCard: CardPaymentToken | SavedCard,
    rememberCard: boolean,
    setCardAsDefault: boolean,
  ) => dispatch(setTokenizedCard({
    tokenizedCard, rememberCard, setCardAsDefault,
  }));

  const fiatAmount = quoteData?.data.quote.fiatAmount;
  const proceedLabel = fiatAmount && selectedFiatAsset
    ? t(LABELS.PAY_AMOUNT, {
      amount: formatFiatCurrencyI18n(i18n.language)(Number(fiatAmount), selectedFiatAsset),
    })
    : t(LABELS.PAY);

  useEffect(() => {
    fetchQuote();
  }, []);

  useEffect(() => {
    if (isPaymentError) {
      resetPaymentError();
    }
  }, []);

  useEffect(() => {
    if (isPaymentError) {
      resetPaymentError();
    }
  }, []);

  const completePaymentGeneric = (quoteId: string, paymentToken: string) => {
    const payload: OrderCompletionPayload = {
      [PaymentMethod.Card]: {
        quoteId,
        walletAddress: withdrawalAddress,
        walletTag: withdrawalAddressTag,
        paymentToken,
        remember: rememberCard,
        setCardAsDefault: isCardSetByDefault,
        side: Flow.Buy,
        externalOrderId,
        externalUserId,
        redirectUrl,
      },
      [PaymentMethod.ApplePay]: {
        quoteId,
        walletAddress: withdrawalAddress,
        walletTag: withdrawalAddressTag,
        paymentToken,
        paymentMethod: PaymentMethod.ApplePay,
        side: Flow.Buy,
        externalOrderId,
        externalUserId,
        redirectUrl,
      },
      [PaymentMethod.GooglePay]: {
        quoteId,
        walletAddress: withdrawalAddress,
        walletTag: withdrawalAddressTag,
        paymentToken,
        paymentMethod: PaymentMethod.GooglePay,
        side: Flow.Buy,
        externalOrderId,
        externalUserId,
        redirectUrl,
      },
    }[selectedPaymentMethod ?? PaymentMethod.Card];

    return completePaymentMutation(payload) as Promise<ReduxToolkitResponse<OrderCompletionResponse>>;
  };

  // Use fiat based quote for charge calls to keep fiatAmount
  // consistent across 3DS verification and payment completion
  const fetchQuote = (isPaying?: boolean) => {
    if (!authToken || !selectCryptoAsset || !selectFiatAsset) {
      throw new Error('Missing quote payload');
    }

    const quoteDataWhichDependsOnExchangeDirection: Partial<QuotePayload> = isPaying || order.exchangeDirection === CurrencyType.Fiat
      ? { fiatAmount }
      : { cryptoAmount: order.cryptoAmount };

    const payload: QuoteAction = {
      payload: {
        cryptoCurrencyCode: selectedCryptoAsset!.symbol,
        fiatCurrencyCode: selectedFiatAsset!.symbol,
        walletAddress: withdrawalAddress,
        walletTag: withdrawalAddressTag,
        paymentMethod: defaultPaymentMethod ?? undefined,
        ...quoteDataWhichDependsOnExchangeDirection,
      },
      includeToken: Boolean(authToken),
    };

    if (selectedPaymentMethod === PaymentMethod.Card) {
      payload.payload.paymentMethod = selectedPaymentMethod;
      payload.payload.paymentToken = tokenizedCard?.id;
    }

    return quoteMutation(payload) as Promise<ReduxToolkitResponse<{ quote: Quote }>>;
  };

  const onContinue = (
    transactionId: TransactionId | undefined,
    hasCardAuthorizationError: boolean,
    cardAuthorizationErrorMessage?: string,
  ) => {
    if (transactionId) {
      dispatch(setTransactionId(transactionId));
    }

    if (cardAuthorizationErrorMessage) {
      dispatch(setCardAuthorizationErrorMessage(cardAuthorizationErrorMessage));
    }

    dispatch(setHasCardAuthorizationError(hasCardAuthorizationError));
    proceed();
  };

  const showErrorScreen = (errorType: ScreenErrorType) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, goBackToScreen: WorkflowStep.Checkout }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const handleIframedApplePay = async (
    value: string,
    currency: string,
  ) => {
    if (!data?.data?.shift4PublicKey) {
      return ({
        error: 'Cannot pay with AP inside the iframe, no shift4 public key found',
      });
    }

    const widgetId = getQueryParam(ConfigQueryParam.Id);
    if (!widgetId) {
      return ({
        error: 'Cannot pay with AP inside the iframe, no widget id found',
      });
    }

    const response = await payWithApplePayInsideIframe({
      value,
      currency,
      shift4PublicKey: data?.data?.shift4PublicKey,
      widgetId,
      merchantIdentifier: data?.data.merchantId,
      createPaymentMethod:
          createPaymentMethod as (payload: CreatePaymentMethodPayload) => Promise<Response<string | null> | ReduxToolkitError>,
    });

    return response;
  };

  const payWithGPay = async (transactionData: {
    fiatAmount: string;
    fiatCurrencyCode: string;
  }, token: string) => {
    if (!shift4Utils) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const quote = await fetchQuote(true);
    const newQuoteId = quote.data?.data?.quote.quoteId;
    const quoteErrorMessage = quote.error?.data?.errorMessage;

    if (quoteErrorMessage) {
      Logger.error(quoteErrorMessage);
      return;
    }
    if (!newQuoteId) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    if (!fiatAmount || !selectedFiatAsset?.symbol) {
      Logger.error('Unknown fiat amount or currency');
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const paymentMethodResponse = await createPaymentMethod({
      fiatAmount: transactionData.fiatAmount,
      fiatCurrencyCode: transactionData.fiatCurrencyCode,
      type: PaymentMethod.GooglePay,
      googlePay: {
        token,
      },
    });

    if ('error' in paymentMethodResponse) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    if ('data' in paymentMethodResponse) {
      const paymentMethod: GPayPaymentMethod | null = await finishGPayMethodSetup({
        clientObjectId: paymentMethodResponse.data as string,
      });

      if (!paymentMethod) {
        showErrorScreen(ScreenErrorType.PaymentCompletionError);
        return;
      }

      let payment;
      try {
        const payload: OrderCompletionPayload = {
          quoteId: newQuoteId,
          walletAddress: withdrawalAddress,
          walletTag: withdrawalAddressTag,
          paymentToken: paymentMethod.clientObjectId,
          paymentMethod: PaymentMethod.GooglePay,
          remember: rememberCard,
          setCardAsDefault: isCardSetByDefault,
          side: Flow.Buy,
        };

        payment = await completePaymentMutation(payload);
      } catch (e) {
        Logger.error(e);
        showErrorScreen(ScreenErrorType.PaymentCompletionError);
        return;
      }

      const paymentError = 'error' in payment ? payment.error as APIErrorResponse : null;
      const paymentData = 'data' in payment ? payment.data : null;

      const hasCardAuthorizationError = paymentError?.data?.errorType === ApiErrorCode.CardAuthorizationFailed;

      const hasOtherPaymentErrorMessage = paymentError?.data?.errorMessage && !hasCardAuthorizationError;
      if (hasOtherPaymentErrorMessage) {
        return;
      }

      const transactionId = paymentData?.data?.orderUuid;
      const hasGenericError = !transactionId && !hasCardAuthorizationError;
      if (hasGenericError) {
        showErrorScreen(ScreenErrorType.PaymentCompletionError);
        return;
      }

      onContinue(transactionId, hasCardAuthorizationError, paymentError?.data?.errorMessage);
    }
  };

  const finishGPayMethodSetup = async (paymentMethod: GPayPaymentMethod): Promise<GPayPaymentMethod | null> => {
    if (!shift4Utils) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return null;
    }

    let nextPaymentMethod = paymentMethod;
    nextPaymentMethod = await shift4Utils.handlePaymentMethodNextAction(paymentMethod.clientObjectId);
    if (nextPaymentMethod.status === 'chargeable') {
      return nextPaymentMethod;
    }

    if (nextPaymentMethod.status === 'failed') {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return null;
    }

    if (nextPaymentMethod.status === 'pending') {
      await shift4Utils.verifyThreeDSecure({ paymentMethod: nextPaymentMethod.clientObjectId });
      return finishGPayMethodSetup(nextPaymentMethod);
    }

    showErrorScreen(ScreenErrorType.PaymentCompletionError);
    return null;
  };

  const onPayClick = async () => {
    try {
      await handlePayment();
    } catch (e) {
      Logger.error('Payment error:', e);
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
    }
  };

  const handlePayment = async () => {
    if (!shift4Utils) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    if (selectedPaymentMethod === PaymentMethod.Card && !tokenizedCard) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    if (!fiatAmount || !selectedFiatAsset?.symbol) {
      throw new Error('Unknown fiat amount or currency');
    }

    const quote = await fetchQuote(true);
    const newQuoteId = quote.data?.data?.quote.quoteId;
    const quoteErrorMessage = quote.error?.data?.errorMessage;
    const freshQuoteFiatAmount = quote.data?.data?.quote.fiatAmount;

    if (quoteErrorMessage) {
      return;
    }
    if (!newQuoteId) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const getPaymentTokenFunc = {
      [PaymentMethod.Card]: () => payWithCard({
        shift4Utils,
        amount: Number(freshQuoteFiatAmount),
        asset: selectedFiatAsset,
        tokenizedCard: tokenizedCard!,
      }),
      [PaymentMethod.ApplePay]: () => (isRunningInsideIframe()
        ? handleIframedApplePay(freshQuoteFiatAmount!, selectedFiatAsset.symbol)
        : payWithApplePay({
          shift4Utils,
          currency: selectedFiatAsset.symbol,
          value: freshQuoteFiatAmount!,
          createPaymentMethod:
          createPaymentMethod as (payload: CreatePaymentMethodPayload) => Promise<Response<string | null> | ReduxToolkitError>,
        })),
      [PaymentMethod.GooglePay]: () => ({
        paymentToken: null,
        // TODO: Refactor GPay to be part of this
        error: 'Google Pay is not supported',
        onError: () => {},
      }),
    }[selectedPaymentMethod ?? PaymentMethod.Card];

    const paymentTokenResponse = await getPaymentTokenFunc();
    if (paymentTokenResponse.error || !paymentTokenResponse.paymentToken) {
      Logger.error('Error while getting payment token', paymentTokenResponse.error);
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      paymentTokenResponse.onError?.();
      return;
    }

    const orderCreationResponse = await completePaymentGeneric(newQuoteId, paymentTokenResponse.paymentToken);
    const transactionId = orderCreationResponse?.data?.data?.orderUuid;
    const hasCardAuthorizationError = orderCreationResponse?.error?.data?.errorType === ApiErrorCode.CardAuthorizationFailed;

    const hasOtherPaymentErrorMessage = orderCreationResponse?.error?.data?.errorMessage && !hasCardAuthorizationError;
    if (hasOtherPaymentErrorMessage) {
      return;
    }

    const hasGenericError = !transactionId && !hasCardAuthorizationError;
    if (hasGenericError) {
      Logger.error('Error while completing payment', orderCreationResponse?.error);
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    paymentTokenResponse.complete?.();
    onContinue(transactionId, hasCardAuthorizationError, orderCreationResponse?.error?.data?.errorMessage);
  };

  const onEditWallet = () => {
    dispatch(changeStep(WorkflowStep.Wallet));
  };

  const onPaymentMethodPick = (paymentMethod: PaymentMethod | null) => {
    if (!paymentMethod) {
      return;
    }
    dispatch(setPaymentMethod(paymentMethod));
  };

  const onAddCardClick = () => {
    dispatch(setIsAddingCard(true));
    dispatch(changeStep(WorkflowStep.BankDetails));
  };

  const googlePayRequest = quoteData ? createGooglePaymentDataRequest({
    totalPrice: quoteData?.data.quote.fiatAmount!,
    currencyCode: quoteData?.data.quote.fiatCurrencyCode!,
    countryCode: KEYS.COUNTRY_CODE,
  }, appConfig.googlePay.merchant, data?.data?.shift4PublicKey)
    : null;

  return (
    <CheckoutPage
      canGoBack={canGoBack}
      quote={{
        details: quoteData?.data.quote,
        isLoading: quoteIsLoading,
        isError: isQuoteError,
        error: quoteError as ReduxToolkitError,
      }}
      payment={{
        isError: isPaymentError,
        error: paymentError as ReduxToolkitError,
        method: selectedPaymentMethod ?? null,
        googlePayRequest,
      }}
      previews={(
        <div className={classes.previews}>
          <OptionallyVisible visible={Boolean(withdrawalAddress)}>
            <WalletPreview
              asset={selectedCryptoAsset}
              label={t(LABELS.SEND_TO)}
              canEdit={canEditWallet}
              walletAddress={withdrawalAddress!}
              walletAddressTag={withdrawalAddressTag}
              shouldShowMemoOrTag={Boolean(order.tagOrMemo)}
              editWallet={onEditWallet}
            />
          </OptionallyVisible>
          <CardPreview
            paymentMethod={selectedPaymentMethod}
            onPaymentMethodPick={onPaymentMethodPick}
            label={t(LABELS.PAY_WITH)}
            isCardManagementVisibleByDefault={isCardManagementVisibleByDefault}
            cardInfo={tokenizedCard}
            setTokenizedCard={setCard}
            onAddCardClick={onAddCardClick}
            cards={{
              cards: cardsResponse ?? [],
              isLoading: areCardsLoading,
              error: cardsError,
            }}
          />
        </div>
      )}
      additionalInfo={(
        <QuoteAdditionalInfo
          isError={isQuoteError}
          isLoading={quoteIsLoading}
          cryptocurrency={selectedCryptoAsset!}
          fiatCurrency={selectedFiatAsset!}
          fetchQuote={fetchQuote}
          price={quoteData?.data?.quote?.cryptoPrice}
          cryptoAmount={quoteData?.data.quote?.cryptoAmount}
          refetch={!(paymentError as ReduxToolkitError)?.data?.errorMessage}
        />
      )}
      proceedLabel={proceedLabel}
      selectedFiatAsset={selectedFiatAsset!}
      selectedCryptoAsset={selectedCryptoAsset!}
      goBack={goBack}
      fetchQuote={fetchQuote}
      onPayClick={onPayClick}
      payWithGPay={payWithGPay}
      QuoteBreakdown={quoteDetails}
      isPayReady={isPayReady}
      regulatoryText={(<Trans i18nKey={LABELS.REGULATORY_TEXT} components={{ br: <br /> }} />)}
    />
  );
};

export default CheckoutContainer;
